body{
  font-family: Arial, Helvetica, sans-serif;
  font-size: -1px;
  font-family: 'Jost';
  background: linear-gradient(101.31deg, #02176C 2.67%, #0076B6 101.24%);
  /* font-size: 20px; */
}
/* h1,h2,h3,h4,h5,h6,p{
  font-family: 'Secular One', sans-serif;
} */
a{
  text-decoration: none;
}
.gradient-icon {
  display: inline-block;
  background: linear-gradient(101.31deg, #02176C 2.67%, #0076B6 101.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-size: 1.4rem; */
}
.gradient-font{
  background: linear-gradient(99.67deg, #02176C 0.22%, #0076B6 101.28%);
}

.gradient-bg{
  background: radial-gradient(188.61% 125.97% at 50% 50%, #FFFFFF 0%, #bddbec 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.gradient-border{
  border: 1px solid;
  border-image-source: conic-gradient(from 222.83deg at 60.12% 34.85%, #022E7E 0deg, rgba(0, 101, 169, 0) 360deg);
}