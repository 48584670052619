@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "jost", serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.modal-content {
  background: transparent;
  border: none;
}
